import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-29c32667")
const _hoisted_1 = {
  key: 0,
  class: "recommended-title"
}
const _hoisted_2 = { class: "course-all-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "filter-wrapper"
}
const _hoisted_4 = {
  key: 0,
  class: "category-list"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "category-list"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "course-wrapper" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_course_card = _resolveComponent("course-card")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_empty = _resolveComponent("empty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isNetwork)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.$filters.hh_t('allCourseTitle')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isNetwork)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.home)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      to: `/course/category/all`,
                      key: category,
                      custom: ""
                    }, {
                      default: _withCtx(({ navigate }) => [
                        _createElementVNode("li", {
                          class: "category-item",
                          onClick: navigate
                        }, _toDisplayString(category.name), 9, _hoisted_5)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                  (!_ctx.home)
                    ? (_openBlock(), _createElementBlock("li", {
                        key: 0,
                        class: "category-item",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateCate('all')))
                      }, _toDisplayString(_ctx.$filters.hh_t('all')), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: category,
                      class: "category-item",
                      onClick: ($event: any) => (_ctx.updateCate(category.urlKey))
                    }, _toDisplayString(category.name), 9, _hoisted_7))
                  }), 128))
                ]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.list.total > 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.data, (item) => {
                return (_openBlock(), _createBlock(_component_course_card, {
                  class: "list-item",
                  course: item,
                  key: item.id
                }, null, 8, ["course"]))
              }), 128))
            ]),
            _createVNode(_component_pagination, {
              pageSize: _ctx.list.per_page,
              total: _ctx.list.total,
              page: _ctx.page,
              onUpdatePage: _ctx.updatePage
            }, null, 8, ["pageSize", "total", "page", "onUpdatePage"])
          ], 64))
        : (_openBlock(), _createBlock(_component_empty, { key: 2 }))
    ])
  ], 64))
}