
import { defineComponent, PropType } from 'vue'
import { ICourseItem } from '@/service/course/types'
import router from '@/router'

export default defineComponent({
  props: {
    course: {
      type: Object as PropType<ICourseItem>,
      required: true
    }
  },
  setup(props) {
    const navigate = () => {
      if (props.course.urlKey.startsWith('http')) {
        location.href = props.course.urlKey
      } else {
        router.push(`/course/${props.course.urlKey}`)
      }
    }

    return {
      navigate
    }
  }
})
