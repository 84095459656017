
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'pagination',
  props: {
    total: {
      type: Number as PropType<number>,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const handleCurrentChange = (page: number) => {
      context.emit('updatePage', page)
    }
    return {
      handleCurrentChange
    }
  }
})
