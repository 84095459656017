import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-558ad585")
const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "category-label" }
const _hoisted_4 = { class: "category-name" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "card-bottom" }
const _hoisted_7 = { class: "card-content" }
const _hoisted_8 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card-top",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
      }, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.course.category?.parent?.name), 1),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.course.category?.name), 1),
        _createElementVNode("img", {
          src: _ctx.course.cover,
          class: "cover"
        }, null, 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h3", {
          class: "card-title",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
        }, _toDisplayString(_ctx.course.name), 1),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_router_link, {
            to: `/partners/${_ctx.course.network.urlKey}`,
            custom: ""
          }, {
            default: _withCtx(({ navigate }) => [
              _createElementVNode("label", {
                class: "school-label",
                onClick: navigate
              }, _toDisplayString(_ctx.course.network.name), 9, _hoisted_8)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ])
  ]))
}