
import { defineComponent, PropType, ref, onMounted, reactive } from 'vue'
import { ICourseCategory, Paginator, ICourseItem } from '@/service/course/types'
import hhRequest from '@/service'
import Empty from '@/components/empty'
import CourseCard from './course-card.vue'
import Pagination from '@/base-ui/pagination'

export default defineComponent({
  props: {
    isNetwork: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    home: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    category: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    query: {
      type: Object as PropType<any>,
      default: () => ({})
    }
  },
  components: {
    CourseCard,
    Empty,
    Pagination
  },
  setup(props) {
    const categories = ref<ICourseCategory[]>([])
    const list = ref<Paginator<ICourseItem>>({
      current_page: 1,
      last_page: 1,
      per_page: 12,
      total: 0,
      data: []
    })
    const loading = ref(true)
    const page = ref(1)
    const category = ref('all')

    const getList = async () => {
      loading.value = true

      try {
        const params = {
          ...props.query,
          page: page.value,
          category: category.value === 'all' ? undefined : category.value
        }
        list.value = reactive<Paginator<ICourseItem>>(
          await hhRequest.get<Paginator<ICourseItem>>({
            url: '/course',
            params: params
          })
        )
        // console.log(list)
      } finally {
        loading.value = false
      }
    }

    const updatePage = (val: number) => {
      page.value = val
      getList()
    }

    const updateCate = (val: string) => {
      page.value = 1
      category.value = val
      getList()
    }

    onMounted(async () => {
      const response = await hhRequest.get<ICourseCategory[]>({
        url: '/course/category'
      })
      categories.value = response
      await getList()
    })

    return {
      categories,
      list,
      loading,
      page,
      updatePage,
      updateCate
    }
  }
})
