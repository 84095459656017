import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2b2133b8")
const _hoisted_1 = { class: "pagination-wrapper" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      "current-page": _ctx.page,
      onCurrentChange: _ctx.handleCurrentChange,
      background: "",
      layout: !_ctx.$isMobile ? 'prev, pager, next, jumper' : 'prev, next',
      "page-size": _ctx.pageSize,
      total: _ctx.total
    }, null, 8, ["current-page", "onCurrentChange", "layout", "page-size", "total"])
  ]))
}